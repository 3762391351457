<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Adlj
 * @LastEditTime: 2022-10-10 12:13:49
-->
<template>
  <div flex="cross:center">
    <div id="mapDiv" />
  </div>
</template>
<script>
import { _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import util from '@/libs/util'
import option from './option'

export default {
  props: {
    markState: {
      type: String,
      default: '1'
    }
  },

  data () {
    return {
      map: null,//地图对象
      T: null,//地图实例
      markerList: [],//点数组
      infoWin: null,//信息窗口
      screenHeight: document.body.clientHeight, // 屏幕高度
      cottonDataA: [],
      cottonDataB: [],
      option // 地图数据
    }
  },

  watch: {
    markState (val) {
      this.map.clearOverLays();
      this.markerList = []
      // if (val === '1') {
      //   this.getCottonDataA()//mark
      // }

      // if (val === '2') {
      //   this.getCottonDataB()//mark
      // }

      //羊
      if (val === '1') {
        this.getDataYang()//mark
      }

      //鸡
      if (val === '2') {
        this.getDataJi()//mark
      }

      //牛
      if (val === '3') {
        this.getDataNiu()//mark
      }

      //水产
      if (val === '4') {
        this.getDataShui()//mark
      }

      //猪
      if (val === '5') {
        this.getDataZhu()//mark
      }

      //鸽
      if (val === '6') {
        this.getDataGe()//mark
      }

    }
  },

  mounted () {
    this.T = window.T
    var imageURL = 'http://t0.tianditu.gov.cn/img_w/wmts?' +
      'SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles' +
      '&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=0e37d14abb5af6aa4caa68578e439c2e'
    // TileLayer添加图层所用的构造函数
    var lay = new this.T.TileLayer(imageURL, { minZoom: 10, maxZoom: 20 }) // 地图图层
    var config = { layers: [lay] }

    this.map = new this.T.Map('mapDiv', config) // 地图实例
    this.map.centerAndZoom(new this.T.LngLat(86.16064373535157, 41.71617988264258), 12)
    // //允许鼠标双击放大地图
    this.map.enableScrollWheelZoom()
    this.map.setMapType(window.TMAP_HYBRID_MAP) // 设置地图位地星混合图层

    this.getDataYang()

    //关闭信息窗口
    window.onCloseInfoWindow = () => {
      this.onCloseInfoWindow()
    }
  },

  methods: {
    // 羊
    getDataYang () {
      const _this = this
      _this.option.animalList.forEach(item => {
        if (item.series === '羊') {
          const icon = new this.T.Icon({
            iconUrl: 'https://api.lztl.cn/admin/sys-file/lztl-assp/2022/4/3b326be0db7c47b195e03b9b6da60eef.png',
            id: 'test',
            iconSize: new this.T.Point(30, 36),
            iconAnchor: new this.T.Point(0, 0)
          })


          let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
          this.markerList.push(marker)
          this.map.addOverLay(marker);
          // const infoWin = new this.T.InfoWindow()

          const sContent =
            "<div style='width: 100%;color:#fff'>" +

            "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
            "<div style='font-size: 16px;font-weight: bold;color: #fff;'>组织机构：" + item.name + "</div>" +
            "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
            "</div>" +
            "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

            "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +

            "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>户主：" +
            item.holder + '\n\t\n\t存栏数：' + item.nums + '\n\t\n\t手机号码：' + item.phoneNum +
            "</div>" +

            "</div>";

          marker.addEventListener("click", function (e) {
            _this.map.panTo(e.lnglat, 12)
            var point = e.lnglat
            _this.infoWin = new _this.T.InfoWindow(e, {
              minWidth: '450',
              closeButton: true, // 显示关闭按钮
              closeOnClick: true, // 开启点击地图关闭弹窗
              offset: new _this.T.Point(20, 0)
            }); // 创建信息窗口对象
            _this.infoWin.setContent(sContent);
            _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

          })

        }
      })
    },
    // 鸡
    getDataJi () {
      const _this = this
      _this.option.animalList.forEach(item => {
        if (item.series === '鸡') {
          const icon = new this.T.Icon({
            iconUrl: 'https://api.lztl.cn/admin/sys-file/lztl-assp/2022/4/c441e348289743bda4882c18f3588f35.png',
            id: 'test',
            iconSize: new this.T.Point(30, 37),
            iconAnchor: new this.T.Point(0, 0)
          })


          let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
          this.markerList.push(marker)
          this.map.addOverLay(marker);

          const sContent =
            "<div style='width: 100%;color:#fff'>" +

            "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
            "<div style='font-size: 16px;font-weight: bold;color: #fff;'>组织机构：" + item.name + "</div>" +
            "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
            "</div>" +
            "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

            "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +

            "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>户主：" +
            item.holder + '\n\t\n\t存栏数：' + item.nums + '\n\t\n\t手机号码：' + item.phoneNum +
            "</div>" +

            "</div>";

          marker.addEventListener("click", function (e) {
            _this.map.panTo(e.lnglat, 12)
            var point = e.lnglat
            _this.infoWin = new _this.T.InfoWindow(e, {
              minWidth: '450',
              closeButton: true, // 显示关闭按钮
              closeOnClick: true, // 开启点击地图关闭弹窗
              offset: new _this.T.Point(20, 0)
            }); // 创建信息窗口对象
            _this.infoWin.setContent(sContent);
            _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

          })

        }
      })
    },
    // 牛
    getDataNiu () {
      const _this = this
      _this.option.animalList.forEach(item => {
        if (item.series === '牛') {
          const icon = new this.T.Icon({
            iconUrl: 'https://api.lztl.cn/admin/sys-file/lztl-assp/2022/4/3696211d60994cf2bae90904caea23ce.png',
            id: 'test',
            iconSize: new this.T.Point(30, 37),
            iconAnchor: new this.T.Point(0, 0)
          })


          let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
          this.markerList.push(marker)
          this.map.addOverLay(marker);
          // const infoWin = new this.T.InfoWindow()

          const sContent =
            "<div style='width: 100%;color:#fff'>" +

            "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
            "<div style='font-size: 16px;font-weight: bold;color: #fff;'>组织机构：" + item.name + "</div>" +
            "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
            "</div>" +
            "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

            "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +

            "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>户主：" +
            item.holder + '\n\t\n\t存栏数：' + item.nums + '\n\t\n\t手机号码：' + item.phoneNum +
            "</div>" +

            "</div>";

          marker.addEventListener("click", function (e) {
            _this.map.panTo(e.lnglat, 12)
            var point = e.lnglat
            _this.infoWin = new _this.T.InfoWindow(e, {
              minWidth: '450',
              closeButton: true, // 显示关闭按钮
              closeOnClick: true, // 开启点击地图关闭弹窗
              offset: new _this.T.Point(20, 0)
            }); // 创建信息窗口对象
            _this.infoWin.setContent(sContent);
            _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

          })

        }
      })
    },
    // 水产养殖
    getDataShui () {
      const _this = this
      _this.option.animalList.forEach(item => {
        if (item.series === '水产养殖') {
          const icon = new this.T.Icon({
            iconUrl: 'https://api.lztl.cn/admin/sys-file/lztl-assp/2022/4/5e766ad31ab54154be1874e99076368b.png',
            id: 'test',
            iconSize: new this.T.Point(30, 37),
            iconAnchor: new this.T.Point(0, 0)
          })


          let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
          this.markerList.push(marker)
          this.map.addOverLay(marker);
          // const infoWin = new this.T.InfoWindow()

          const sContent =
            "<div style='width: 100%;color:#fff'>" +

            "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
            "<div style='font-size: 16px;font-weight: bold;color: #fff;'>组织机构：" + item.name + "</div>" +
            "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
            "</div>" +
            "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

            "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +

            "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>户主：" +
            item.holder + '\n\t\n\t存栏数：' + item.nums + '\n\t\n\t手机号码：' + item.phoneNum +
            "</div>" +

            "</div>";

          marker.addEventListener("click", function (e) {
            _this.map.panTo(e.lnglat, 12)
            var point = e.lnglat
            _this.infoWin = new _this.T.InfoWindow(e, {
              minWidth: '450',
              closeButton: true, // 显示关闭按钮
              closeOnClick: true, // 开启点击地图关闭弹窗
              offset: new _this.T.Point(20, 0)
            }); // 创建信息窗口对象
            _this.infoWin.setContent(sContent);
            _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

          })

        }
      })
    },
    // 猪
    getDataZhu () {
      const _this = this
      _this.option.animalList.forEach(item => {
        if (item.series === '猪') {
          const icon = new this.T.Icon({
            iconUrl: 'https://api.lztl.cn/admin/sys-file/lztl-assp/2022/4/1c79d148eeb848b6a4e03fecd9984c57.png',
            id: 'test',
            iconSize: new this.T.Point(30, 37),
            iconAnchor: new this.T.Point(0, 0)
          })


          let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
          this.markerList.push(marker)
          this.map.addOverLay(marker);
          // const infoWin = new this.T.InfoWindow()

          const sContent =
            "<div style='width: 100%;color:#fff'>" +

            "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
            "<div style='font-size: 16px;font-weight: bold;color: #fff;'>组织机构：" + item.name + "</div>" +
            "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
            "</div>" +
            "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

            "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +

            "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>户主：" +
            item.holder + '\n\t\n\t存栏数：' + item.nums + '\n\t\n\t手机号码：' + item.phoneNum +
            "</div>" +

            "</div>";

          marker.addEventListener("click", function (e) {
            _this.map.panTo(e.lnglat, 12)
            var point = e.lnglat
            _this.infoWin = new _this.T.InfoWindow(e, {
              minWidth: '450',
              closeButton: true, // 显示关闭按钮
              closeOnClick: true, // 开启点击地图关闭弹窗
              offset: new _this.T.Point(20, 0)
            }); // 创建信息窗口对象
            _this.infoWin.setContent(sContent);
            _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

          })

        }
      })
    },
    // 鸽
    getDataGe () {
      const _this = this
      _this.option.animalList.forEach(item => {
        if (item.series === '鸽') {
          const icon = new this.T.Icon({
            iconUrl: 'https://api.lztl.cn/admin/sys-file/lztl-assp/2022/4/648624041a6d4d24b1c3abb53ed36012.png',
            id: 'test',
            iconSize: new this.T.Point(30, 37),
            iconAnchor: new this.T.Point(0, 0)
          })


          let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
          this.markerList.push(marker)
          this.map.addOverLay(marker);
          // const infoWin = new this.T.InfoWindow()

          const sContent =
            "<div style='width: 100%;color:#fff'>" +

            "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
            "<div style='font-size: 16px;font-weight: bold;color: #fff;'>组织机构：" + item.name + "</div>" +
            "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
            "</div>" +
            "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

            "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +

            "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>户主：" +
            item.holder + '\n\t\n\t存栏数：' + item.nums + '\n\t\n\t手机号码：' + item.phoneNum +
            "</div>" +

            "</div>";

          marker.addEventListener("click", function (e) {
            _this.map.panTo(e.lnglat, 12)
            var point = e.lnglat
            _this.infoWin = new _this.T.InfoWindow(e, {
              minWidth: '450',
              closeButton: true, // 显示关闭按钮
              closeOnClick: true, // 开启点击地图关闭弹窗
              offset: new _this.T.Point(20, 0)
            }); // 创建信息窗口对象
            _this.infoWin.setContent(sContent);
            _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

          })

        }
      })
    },
    getCottonDataA () {
      _GetAspScreenComponentData('KELNEW-XM-SCYZQ').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr1.push({
              title: item.data_title,
              value: item.data_value,
              geoInfo: item.geos.split('\n').map(geo => {
                let lngLat = geo.split(',')
                return { lng: Number(lngLat[0]), lat: Number(lngLat[1]) }
              })
            })
          })
          this.cottonDataA = arr1
          this.onCotton()
        }
      })
    },
    /*第一个 */
    onCotton () {
      var points = [];

      this.cottonDataA.forEach(item => {
        const pointsData = []
        console.log(item)
        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#f52cff", weight: 1, opacity: 0.9, fillColor: "#a7dbc8", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          e.conent = item
          _this.onLandMesB(e)
        });
      });
    },

    onLandMesB (e) {
      let latlng = util.getPolygonCenter(e.conent.geoInfo)
      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div style='width: 100%;color:#fff'>" +

        "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +

        "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
        e.conent.value +
        "</div>" +

        "</div>";

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true // 开启点击地图关闭弹窗
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
      console.log(point);
    },


    getCottonDataB () {
      _GetAspScreenComponentData('KELNEW-XM-JQYZQ').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr1.push({
              title: item.data_title,
              value: item.data_value,
              geoInfo: item.geos.split('\n').map(geo => {
                let lngLat = geo.split(',')
                return { lng: Number(lngLat[0]), lat: Number(lngLat[1]) }
              })
            })
          })
          this.cottonDataB = arr1
          this.onCottonB()
        }
      })
    },
    /*第一个 */
    onCottonB () {
      var points = [];

      this.cottonDataB.forEach(item => {
        const pointsData = []
        console.log(item)
        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#13c081", weight: 1, opacity: 0.9, fillColor: "#ade4d7", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          e.conent = item
          _this.onLandMes(e)
        });
      });
    },

    onLandMes (e) {
      let latlng = util.getPolygonCenter(e.conent.geoInfo)
      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div style='width: 100%;color:#fff'>" +

        "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +

        "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
        e.conent.value +
        "</div>" +

        "</div>";

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true // 开启点击地图关闭弹窗
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
      console.log(point);
    },
    /*关闭信息窗口 */
    onCloseInfoWindow () {
      this.infoWin.closeInfoWindow()
    }
  }
}
</script>
<style>
.tdt-infowindow-content-wrapper,
.tdt-infowindow-tip {
  background: rgba(0, 38, 25, 0.66);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5),
    inset 0px 4px 10px 0px rgba(163, 255, 227, 0.4);
  border: 1px solid #008166;
  backdrop-filter: blur(6px);
}

.tdt-container a.tdt-infowindow-close-button {
  color: #fff;
}
</style>
<style scoped lang="scss">
#mapDiv {
  width: 2560px;
  height: 1080px;
}
</style>
