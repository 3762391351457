<template>
  <div class="left-top-container2"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:center">
      <div class="txt">
        单位：只
      </div>
      <div class="title">
        {{ title }}
      </div>
    </div>
    <div class="card-body"
         flex="dir:top">
      <div class="chart"
           ref="chart"
           :style="`zoom:${zoom};transform:scale(${1/zoom});transform-origin:0 0`" />
    </div>
  </div>
</template>

<script>
import option from './option'
import { getTraceDataCount, getTrVehicleCount } from '@/api/assp-trace-collectData/index'
import {/*_GetAspScreenMenu ,*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息

export default {
  name: 'LeftTopItem',
  props: {
    region: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      option,
      title: '',
      myChart: null,
      data: {},//数据
      lineState: {},//在线状态数据
      params: {
        region: '652928'
      }, zoom: 1
    }
  },

  watch: {
    region (val) {
      this.params.region = val
      this.getData()
    }
  },

  mounted () {
    let zoom = document.body.style.zoom
    if (zoom) {
      this.zoom = 1 / document.body.style.zoom
    } else {
      this.zoom = 1 / 1
    }
    window.addEventListener("resize", () => {
      let zoom = document.body.style.zoom
      if (zoom) {
        this.zoom = 1 / document.body.style.zoom
      } else {
        this.zoom = 1 / 1
      }
    })
    this.initChart()
    this.getSreenData()
  },

  methods: {
    //获取数据
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-XMY-DWYYLB').then(res => {
        if (res) {
          const msgData = []
          res.data.data.forEach(item => {
            msgData.push(
              {
                value: item.vaule_data,
                name: item.name_data
              }

            )
          });

          this.option.series[0].data = msgData
          this.title = res.data.form.name
          this.myChart.setOption(this.option)
          let sizeFun = function () {
            this.myChart.resize()
          }
          window.addEventListener('resize', sizeFun)
        }
      })
    },
    initChart () {
      // 基于准备好的dom，初始化echarts实例
      this.myChart = this.$echarts.init(this.$refs.chart)
      // 绘制图表
      this.myChart.setOption(this.option)
    },
    getData () {
      getTraceDataCount(this.params).then(res => {
        this.data = res
        if (res.landArea) {
          this.data.landArea = res.landArea.toFixed(2)
        }
      })

      getTrVehicleCount(this.params.region).then(res => {
        this.lineState = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
</style>