<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Pancras
 * @LastEditTime: 2023-02-03 18:38:12
-->
<template>
  <div flex="cross:center">
    <div id="mapDiv" />
    <div class="cropGrowth"
         v-if="timeLineShow">
      <el-timeline class="timeline">
        <el-timeline-item class="timeline-item"
                          v-for="(radio, index) in option.radioMonths"
                          :key="index"
                          :timestamp="radio.content"
                          @click.native="getRadio(index)"
                          :class="[radioActive == index ? 'active-time-item':'']"
                          :size="radio.size">
          {{ radio.month }}
        </el-timeline-item>
      </el-timeline>
    </div>
    <div>
      <cottonPopu :cotton-egg-params="cottonEggParams" />
    </div>
    <div>
      <plantPopu :plant-params="plantParams" />
    </div>
    <div>
      <vehiclePopu :vehicle-id-sell="vehicleId_Sell"
                   @sellDrawer="getSellDrawer"
                   :vehicle-id-gather="vehicleId_Gather" />
    </div>
  </div>
</template>
<script>
import cottonPopu from './components/cottonEggPopu'
import vehiclePopu from './components/vehiclePopu'
import plantPopu from './components/plantPopo'
import { _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import util from '@/libs/util'
import option from './option'

export default {
  props: {
    markState: {
      type: String,
      default: '1'
    }
  },
  components: {
    cottonPopu,
    plantPopu,
    vehiclePopu
  },

  data () {
    return {
      map: null,//地图对象
      T: null,//地图实例
      markerList: [],//点数组
      infoWin: null,//信息窗口
      screenHeight: document.body.clientHeight, // 屏幕高度
      cottonDataA: [],
      cottonDatab: [],
      cottonDatac: [],
      option,
      radioActive: '',
      timeLineShow: false,
      vehicleId_Sell: null,//车辆id查询交售
      vehicleId_Gather: null,//车辆id查询采收
      marker: null,
      cottonEggParams: {},//当前点击棉蛋参数
      plantParams: {},//当前点击工厂参数
    }
  },

  mounted () {
    this.T = window.T
    var imageURL = 'http://t0.tianditu.gov.cn/img_w/wmts?' +
      'SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles' +
      '&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=0e37d14abb5af6aa4caa68578e439c2e'
    // TileLayer添加图层所用的构造函数
    var lay = new this.T.TileLayer(imageURL, { minZoom: 10, maxZoom: 20 }) // 地图图层
    var config = { layers: [lay] }

    this.map = new this.T.Map('mapDiv', config) // 地图实例
    this.map.centerAndZoom(new this.T.LngLat(86.16064373535157, 41.71617988264258), 12)
    // //允许鼠标双击放大地图
    this.map.enableScrollWheelZoom()
    this.map.setMapType(window.TMAP_HYBRID_MAP) // 设置地图位地星混合图层

    this.getmarkDataA()//棉蛋mark
    // this.getCotton()//棉蛋
    // this.getPlant()//工厂
    // this.getVehicle()//获取车辆

    /*公布方法 */

    //关闭信息窗口
    window.onCloseInfoWindow = () => {
      this.onCloseInfoWindow()
    }

    window.onCottonFarmers = () => {
      this.onCottonFarmers()
    };

    window.onCottonTransport = () => {
      this.onCottonTransport()
    };

    window.onCottonSell = () => {
      this.onCottonSell()
    };

    window.onPlantPrice = () => {
      this.onPlantPrice()
    };

    window.onPlantExamine = () => {
      this.onPlantExamine()
    };

    window.onPlantSell = () => {
      this.onPlantSell()
    };

    window.onProcess = () => {
      this.onProcess()
    };

    window.onVehicleGatherDetails = () => {
      this.onVehicleGatherDetails()
    };

    window.onVehicleSellDetails = () => {
      this.onVehicleSellDetails()
    };
  },

  watch: {
    markState (val) {
      this.map.clearOverLays();
      this.markerList = []
      this.timeLineShow = false
      if (val === '1') {
        this.getmarkDataA()//mark
      }

      if (val === '2') {
        this.getmarkDatab()//mark
      }

      if (val === '3') {
        this.getmarkDatac()//mark
      }

      //香梨分布
      if (val === '4') {
        const bd = new this.T.LngLatBounds(new this.T.LngLat(85.0481562940053, 41.275457458956), new this.T.LngLat(86.70307142356025, 41.95717303712637))
        const fbimg = new this.T.ImageOverlay('http://8.142.105.233:6080/arcgis/rest/services/ZZGJMapService/MapServer/export?dpi=96&transparent=true&format=png8&bbox=85.0481562940053%2C41.275457458956%2C86.70307142356025%2C41.95717303712637&bboxSR=4326&imageSR=4326&size=1391%2C573&f=image', bd, {
          opacity: 1,
          alt: ''
        })
        this.map.addOverLay(fbimg)
        this.map.panTo(new this.T.LngLat(86.06064373535157, 41.71617988264258), [10])
      }
      //香梨长势分析
      if (val === '5') {
        this.getRadio(0)//mark
        this.timeLineShow = true
      }

    }
  },

  methods: {
    /*获取棉蛋数据*/
    getCotton () {
      let arr = [
        {
          lng: 85.99,
          lat: 41.72,
        },
        {
          lng: 85.99,
          lat: 41.71,
        },
        {
          lng: 85.99,
          lat: 41.70,
        },
        {
          lng: 85.99,
          lat: 41.69,
        },
        {
          lng: 85.99,
          lat: 41.68,
        },
        {
          lng: 85.99,
          lat: 41.67,
        },
        {
          lng: 85.98,
          lat: 41.72,
        },
        {
          lng: 85.98,
          lat: 41.71,
        },
        {
          lng: 85.98,
          lat: 41.70,
        },
        {
          lng: 85.98,
          lat: 41.69,
        },
        {
          lng: 85.98,
          lat: 41.68,
        },
        {
          lng: 85.98,
          lat: 41.67,
        },
        {
          lng: 85.97,
          lat: 41.72,
        },
        {
          lng: 85.97,
          lat: 41.71,
        },
        {
          lng: 85.97,
          lat: 41.70,
        },
        {
          lng: 85.97,
          lat: 41.69,
        },
        {
          lng: 85.97,
          lat: 41.68,
        },
        {
          lng: 85.97,
          lat: 41.67,
        },
        {
          lng: 85.96,
          lat: 41.72,
        },
        {
          lng: 85.96,
          lat: 41.71,
        },
        {
          lng: 85.96,
          lat: 41.70,
        },
        {
          lng: 85.96,
          lat: 41.69,
        },
        {
          lng: 85.96,
          lat: 41.68,
        },
        {
          lng: 85.96,
          lat: 41.67,
        }
      ]
      arr.forEach(item => {
        const icon = new this.T.Icon({
          iconUrl: '/image/icon/md.png',
          id: 'test',
          iconSize: new this.T.Point(50, 62),
          iconAnchor: new this.T.Point(0, 0)
        })
        this.marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(this.marker)
        this.map.addOverLay(this.marker);
        this.marker.on('click', this.cottonmarkerClick);
      })
    },

    /*点击棉蛋mark */
    cottonmarkerClick (e) {
      let lnglat = e.lnglat
      let _this = this
      _this.map.panTo(lnglat, 12)
      let content = "<div>" +
        "<div style='margin: 8px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px; 0'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #fff;'>棉蛋信息</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px; 0'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>采收时间</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>2022-9-3</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px; 0'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>采收时间</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>" + '北纬' + e.lnglat.lat + '东经' + e.lnglat.lng + "</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px; 0'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>农户信息</div>" +
        "<div onClick='onCottonFarmers()' style='font-size: 14px;font-weight: 400;color: #ffffff;cursor: pointer;'>详情></div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px; 0'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>拉运信息</div>" +
        "<div onClick='onCottonTransport()' style='font-size: 14px;font-weight: 400;color: #ffffff;cursor: pointer;'>详情></div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px; 0'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>交售信息</div>" +
        "<div onClick='onCottonSell()' style='font-size: 14px;font-weight: 400;color: #ffffff;cursor: pointer;'>详情></div>" +
        "</div>" +
        "</div>"

      var point = lnglat
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '360',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true,// 开启点击地图关闭弹窗
        offset: new _this.T.Point(20, 0)
      }); // 创建信息窗口对象
      _this.infoWin.setContent(content);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
    },

    /*获取工厂数据 */
    getPlant () {
      let arr = [
        {
          lng: 86.08,
          lat: 41.77,
        },
        {
          lng: 86.05,
          lat: 41.75,
        },
        {
          lng: 86.03,
          lat: 41.72,
        },
        {
          lng: 86.12,
          lat: 41.75,
        },
        {
          lng: 86.11,
          lat: 41.76,
        },
        {
          lng: 86.07,
          lat: 41.79,
        },
        {
          lng: 86.10,
          lat: 41.81,
        },
        {
          lng: 86.10,
          lat: 41.75,
        },
        {
          lng: 86.10,
          lat: 41.73,
        },
        {
          lng: 86.12,
          lat: 41.70,
        },
      ]
      arr.forEach(item => {
        const icon = new this.T.Icon({
          iconUrl: '/image/icon/yhc.png',
          id: 'test',
          iconSize: new this.T.Point(50, 62),
          iconAnchor: new this.T.Point(0, 0)
        })
        this.marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(this.marker)
        this.map.addOverLay(this.marker);
        this.marker.on('click', this.plantmarkerClick);
      })
    },

    /*点击工厂mark */
    plantmarkerClick (e) {
      let lnglat = e.lnglat
      let _this = this
      _this.map.panTo(lnglat, 12)
      let content = "<div>" +
        "<div style='margin: 10px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #fff;'>工厂信息</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;width:130px'>轧花厂</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>乌鲁木齐轧花厂</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>今日牌价</div>" +
        "<div onClick='onPlantPrice()' style='font-size: 14px;font-weight: 400;color: #ffffff;cursor: pointer;'>" + '¥' + 200 + '>' + "</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>公检记录</div>" +
        "<div onClick='onPlantExamine()' style='font-size: 14px;font-weight: 400;color: #ffffff;cursor: pointer;'>" + 10 + '批' + '>' + "</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>收购记录</div>" +
        "<div onClick='onPlantSell()' style='font-size: 14px;font-weight: 400;color: #ffffff;cursor: pointer;'>" + 5 + '包' + '>' + "</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>加工记录</div>" +
        "<div onClick='onProcess()' style='font-size: 14px;font-weight: 400;color: #ffffff;cursor: pointer;'>" + 20 + '包' + '>' + "</div>" +
        "</div>"

      var point = lnglat
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '360',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true,// 开启点击地图关闭弹窗
        offset: new _this.T.Point(20, 0)
      }); // 创建信息窗口对象
      _this.infoWin.setContent(content);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
    },

    /*获取车辆数据 */
    getVehicle () {
      let arr = [
        {
          lng: 86.00,
          lat: 41.73,
        },
        {
          lng: 86.05,
          lat: 41.73,
        },
        {
          lng: 86.02,
          lat: 41.75,
        },
        {
          lng: 86.01,
          lat: 41.78,
        },
        {
          lng: 86.00,
          lat: 41.80,
        },
        {
          lng: 86.05,
          lat: 41.78,
        },
        {
          lng: 86.10,
          lat: 41.78,
        },
        {
          lng: 86.15,
          lat: 41.80,
        },
        {
          lng: 86.18,
          lat: 41.79,
        },
        {
          lng: 86.16,
          lat: 41.75,
        },
        {
          lng: 86.10,
          lat: 41.77,
        },
        {
          lng: 86.05,
          lat: 41.70,
        },
        {
          lng: 86.20,
          lat: 41.79,
        },
        {
          lng: 86.23,
          lat: 41.75,
        },
        {
          lng: 86.26,
          lat: 41.73,
        },
        {
          lng: 86.15,
          lat: 41.70,
        },
        {
          lng: 86.20,
          lat: 41.80,
        },
      ]
      arr.forEach((item, index) => {
        let markIcon = ''
        if (index != 6) {
          markIcon = '/image/icon/nj.png'
        } else {
          markIcon = 'https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/7930393403b34d86a366e0f7209f87b8.png'
        }
        const icon = new this.T.Icon({
          iconUrl: markIcon,
          id: 'test',
          iconSize: new this.T.Point(50, 62),
          iconAnchor: new this.T.Point(0, 0)
        })
        this.marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(this.marker)
        this.map.addOverLay(this.marker);
        this.marker.on('click', this.vehiclemarkerClick);
      })
    },

    /*点击车辆mark */
    vehiclemarkerClick (e) {
      let lnglat = e.lnglat
      let _this = this
      _this.map.panTo(lnglat, 12)
      let content = "<div>" +
        "<div style='margin: 14px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #fff;'>车辆信息</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>终端设备号</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>" + 205521 + "</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;width:150px'>当前具体位置</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>库尔勒</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>农机牌号</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>新A 90cs</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>车主姓名</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>张三</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>车主手机号</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>15869588589</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>速度</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>20</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>车机类型</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>农机</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div onClick='onVehicleGatherDetails()' style='font-size: 14px;font-weight: bold;color: #ffffff;'>采收记录</div>" +
        "<div onClick='onVehicleGatherDetails()' style='font-size: 14px;font-weight: 400;color: #ffffff;cursor: pointer;'>详情></div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div onClick='onVehicleSellDetails()' style='font-size: 14px;font-weight: bold;color: #ffffff;'>交售记录</div>" +
        "<div onClick='onVehicleSellDetails()' style='font-size: 14px;font-weight: 400;color: #ffffff;cursor: pointer;'>详情></div>" +
        "</div>" +
        "</div>"

      var point = lnglat
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '360',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true,// 开启点击地图关闭弹窗
        offset: new _this.T.Point(20, 0)
      }); // 创建信息窗口对象
      _this.infoWin.setContent(content);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
    },

    /*打开二级窗口 */
    onVehicleSellDetails () {
      let state = Math.round(Math.random() * 100)//随机数
      this.vehicleId_Sell = state
    },

    /*打开二级窗口 */
    onVehicleGatherDetails () {
      let state = Math.round(Math.random() * 100)//随机数
      this.vehicleId_Gather = state
    },

    //接收车辆弹窗返回参数
    getSellDrawer (val) {
      console.log(val)
      this.vehicleId_Sell = null
      this.vehicleId_Gather = null
    },

    /*点击工厂今日牌价 */
    onPlantPrice () {
      let state = Math.round(Math.random() * 100)//随机数
      let params = {
        id: state,
        type: 'price'
      }
      this.plantParams = params
    },

    /*点击工厂公检信息 */
    onPlantExamine () {
      let state = Math.round(Math.random() * 100)//随机数
      let params = {
        id: state,
        type: 'examine'
      }
      this.plantParams = params
    },

    /*点击工厂收购信息 */
    onPlantSell () {
      let state = Math.round(Math.random() * 100)//随机数
      let params = {
        id: state,
        type: 'sell'
      }
      this.plantParams = params
    },


    /*点击工厂加工信息 */
    onProcess () {
      let state = Math.round(Math.random() * 100)//随机数
      let params = {
        id: state,
        type: 'process'
      }
      this.plantParams = params
    },

    onCottonFarmers () {
      let state = Math.round(Math.random() * 100)//随机数
      let params = {
        type: 'farmer',
        id: state
      }
      this.cottonEggParams = params
    },

    onCottonTransport () {
      let state = Math.round(Math.random() * 100)//随机数
      let params = {
        type: 'transport',
        id: state
      }
      this.cottonEggParams = params
    },

    onCottonSell () {
      let state = Math.round(Math.random() * 100)//随机数
      let params = {
        type: 'sell',
        id: state
      }
      this.cottonEggParams = params
    },

    getRadio (index) {

      const bd = new this.T.LngLatBounds(new this.T.LngLat(85.33821942583783, 41.53954086798456), new this.T.LngLat(86.4752479993616, 41.86889865706975))
      this.fbimg = new this.T.ImageOverlay(this.option.radioLnglat[index], bd, {
        opacity: 1
      })

      this.map.addOverLay(this.fbimg)
      this.radioActive = index
      this.map.centerAndZoom(new this.T.LngLat(86.06064373535157, 41.71617988264258), 11)
    },
    getmarkDataA () {
      _GetAspScreenComponentData('KELNEW-LY-YBLLQZMJ').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {

            arr1.push({
              title: item.data_title,
              name1: item.name_one,
              msg1: item.msg_one,
              name2: item.name_two,
              msg2: item.msg_two,
              name3: item.name_three,
              msg3: item.msg_three,
              name4: item.name_four,
              msg4: item.msg_four,
              geoInfo: item.geos.split('\n').map(geo => {
                let lngLat = geo.split(',')
                return { lng: Number(lngLat[0]), lat: Number(lngLat[1]) }
              })
            })
          })
          this.cottonDataA = arr1
          this.onCottonA()
        }
      })
    },
    /*第一个 */
    onCottonA () {
      var points = [];

      this.cottonDataA.forEach(item => {
        const pointsData = []

        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#c0be13", weight: 1, opacity: 0.9, fillColor: "#ade4d7", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          e.conent = item
          _this.onLandMesA(e)
        });
      });
    },

    onLandMesA (e) {


      let latlng = util.getPolygonCenter(e.conent.geoInfo)

      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div style='width: 100%;color:#fff'>" +

        "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
        "<div style='width: 50%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;width:80px'>" + e.conent.name1 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + e.conent.msg1 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name2 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg2 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 50%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name3 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg3 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name4 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg4 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +


        "</div>";

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true // 开启点击地图关闭弹窗
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

    },

    getmarkDatab () {
      _GetAspScreenComponentData('KELNEW-LY-XLBT').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {

            arr1.push({
              title: item.data_title,
              name1: item.name_one,
              msg1: item.msg_one,
              name2: item.name_two,
              msg2: item.msg_two,
              name3: item.name_three,
              msg3: item.msg_three,
              name4: item.name_four,
              msg4: item.msg_four,
              geoInfo: item.geos.split('\n').map(geo => {
                let lngLat = geo.split(',')
                return { lng: Number(lngLat[0]), lat: Number(lngLat[1]) }
              })
            })
          })
          this.cottonDatab = arr1
          this.onCottonb()
        }
      })
    },
    /*第一个 */
    onCottonb () {
      var points = [];

      this.cottonDatab.forEach(item => {
        const pointsData = []

        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#13c081", weight: 1, opacity: 0.9, fillColor: "#ade4d7", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          e.conent = item
          _this.onLandMesB(e)
        });
      });
    },

    onLandMesB (e) {


      let latlng = util.getPolygonCenter(e.conent.geoInfo)

      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div style='width: 100%;color:#fff'>" +

        "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;width:80px'>" + e.conent.name1 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + e.conent.msg1 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name2 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg2 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name3 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg3 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name4 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg4 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "</div>";

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true // 开启点击地图关闭弹窗
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

    },

    getmarkDatac () {
      _GetAspScreenComponentData('KELNEW-LY-HFKFSJ').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {

            arr1.push({
              title: item.data_title,
              name1: item.name_one,
              msg1: item.msg_one,
              name2: item.name_two,
              msg2: item.msg_two,
              name3: item.name_three,
              msg3: item.msg_three,
              name4: item.name_four,
              msg4: item.msg_four,
              geoInfo: item.geos.split('\n').map(geo => {
                let lngLat = geo.split(',')
                return { lng: Number(lngLat[0]), lat: Number(lngLat[1]) }
              })
            })
          })
          this.cottonDatac = arr1
          this.onCottonc()
        }
      })
    },
    /*第一个 */
    onCottonc () {
      var points = [];

      this.cottonDatac.forEach(item => {
        const pointsData = []

        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#00b5fd", weight: 1, opacity: 0.9, fillColor: "#b8ecd9", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          e.conent = item
          _this.onLandMesC(e)
        });
      });
    },

    onLandMesC (e) {


      let latlng = util.getPolygonCenter(e.conent.geoInfo)

      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div style='width: 100%;color:#fff'>" +

        "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;width:80px'>" + e.conent.name1 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + e.conent.msg1 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name2 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg2 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name3 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg3 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name4 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg4 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "</div>";

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true // 开启点击地图关闭弹窗
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

    },
    /*关闭信息窗口 */
    onCloseInfoWindow () {
      this.infoWin.closeInfoWindow()
    }
  }
}
</script>
<style>
.tdt-infowindow-content-wrapper,
.tdt-infowindow-tip {
  background: rgba(0, 38, 25, 0.66);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5),
    inset 0px 4px 10px 0px rgba(163, 255, 227, 0.4);
  border: 1px solid #008166;
  backdrop-filter: blur(6px);
}

.tdt-container a.tdt-infowindow-close-button {
  color: #fff;
}
</style>
<style scoped lang="scss">
#mapDiv {
  z-index: 997;
  width: 2560px;
  height: 1080px;
  position: fixed;
  left: 0;
  top: calc(50% - 540px);
}
</style>
<style>
.cropGrowth .el-timeline-item__content {
  z-index: 3;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 18px;
  margin-top: -4px;
}
.cropGrowth .el-timeline-item__node {
  left: -8px;
  width: 20px;
  height: 20px;
  background-color: rgb(255, 255, 255);
}
.cropGrowth .el-timeline-item__tail {
  position: absolute;
  left: 1px;
  height: 100%;
  border-left: 3px solid rgb(255, 255, 255);
}
.cropGrowth .active-time-item .el-timeline-item__node {
  left: -8px;
  width: 20px;
  height: 20px;
  background-color: #008166;
}
.cropGrowth .active-time-item .el-timeline-item__wrapper {
  position: relative;
  width: 36px;
  height: 18px;
  padding: 12px;
  border-radius: 10%;
  margin-left: 30px;
  background-color: #008166;
}
.cropGrowth .active-time-item .el-timeline-item__content {
  color: #b7e2ff;
  margin-left: 0px;
  font-size: 18px;
  text-shadow: 0 0 black;
}

.timeline {
  width: 90px;
  z-index: 3;
  margin-top: -38%;
}
.timeline-item {
  z-index: 3;
  cursor: pointer;
}

.cropGrowth {
  position: absolute;
  left: 18%;
  top: 40%;
  z-index: 999;
}
</style>
