/*
 * @Description: 用户登陆接口
 * @Author: Pancras
 * @Date: 2019-10-24 14:17:46
 * @LastEditors: Pancras
 * @LastEditTime: 2022-08-26 12:33:58
 */
import request from '@/libs/axios'

/*数据汇总 */
export function getTraceDataCount (data) {
  return request({
    url: '/tracing/tr-trace/data/count',
    method: 'GET',
    params: data
  })
}

/*根据区划统计农机在线离线作业中数量 */
export function getTrVehicleCount (region) {
  return request({
    url: '/platform/asp-vehicle/region/count/' + region,
    method: 'GET',
  })
}

/*溯源进度 */
export function getTraceProgress (data) {
  return request({
    url: '/tracing/tr-trace/traceProgress/list/',
    method: 'GET',
    params: data
  })
}

/*交售情况 */
export function getTraceSellsituationList (data) {
  return request({
    url: '/tracing/tr-trace/sellsituation/list/',
    method: 'GET',
    params: data
  })
}

/*根据region获取今日牌价 */
export function getTraceTodayPrice (region) {
  return request({
    url: '/tracing/tr-trace/today/price/region/' + region,
    method: 'GET',
  })
}

/*根据region获取交售排行 */
export function getTrsellrankingList (data) {
  return request({
    url: '/tracing/tr-trace/sellranking/list',
    method: 'GET',
    params: data
  })
}

/*根据region获取乡镇交售情况 */
export function gettownshipsellrankingList (data) {
  return request({
    url: '/tracing/tr-trace/townshipsellranking/list',
    method: 'GET',
    params: data
  })
}

/*根据region获取棉蛋数量 */
export function getcottonNumber (data) {
  return request({
    url: '/tracing/tr-trace/cotton/count',
    method: 'GET',
    params: data
  })
}






