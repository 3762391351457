export default {
  color: ['#68FFD6', '#EB5E00', '#5AFF2F', '#FF2DFF', '#008CFF'],
  legend: {
    textStyle: {
      color: '#FFFFFF'
    },
    itemWidth: 26,
    itemHeight: 6,
    padding: [
      20,  // 上
      0, // 右
      20,  // 下
      0, // 左
    ],
  },
  yAxis: {
    type: 'category',
    data: ['2022', '2021', '2020', '2019'],
    axisLabel: {
      color: '#B1B6AE'
    },
    axisTick: {
      show: false
    },
    axisLine: {
      show: false
    }
  },
  tooltip: {
    trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
    axisPointer: {// 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    },
    formatter: '{b} : {c}吨',
  },
  xAxis: {
    type: 'value',
    axisLabel: {
      color: '#FFFFFF'
    },
    splitLine: {
      lineStyle: {
        color: '#495A4D',
        type: 'dashed'
      }
    }
  },
  grid: {
    top: 50,
    botttom: 0,
    left: 50,
  },
  series: [
    {
      name: '',// '水培',
      data: [],// [10, 20, 20, 20, 22],
      type: 'bar',
      barWidth: '12',
      lineStyle: {
        color: '#00FFD4',
      },
      stack: 'total',
      label: {
        show: true,
        position: "top",
        color: '#00E5B4'
      },
      itemStyle: {
        normal: {
          barBorderRadius: [0, 3, 3, 0]
        }
      },
    },
    {
      name: '',//'青贮',
      data: [],//[44, 32, 32, 32, 60],
      type: 'bar',
      barWidth: '12',
      lineStyle: {
        color: '#00FFD4',
      },
      stack: 'total',
      label: {
        show: true,
        position: "top",
        color: '#00E5B4'
      },
      itemStyle: {
        normal: {
          barBorderRadius: [0, 3, 3, 0]
        }
      },
    },
    {
      name: '',// '麦草',
      data: [],// [60, 60, 60, 60, 60],
      type: 'bar',
      barWidth: '12',
      lineStyle: {
        color: '#00FFD4',
      },
      stack: 'total',
      label: {
        show: true,
        position: "top",
        color: '#00E5B4'
      },
      itemStyle: {
        normal: {
          barBorderRadius: [0, 3, 3, 0]
        }
      },
    },
    {
      name: '',// '苜蓿',
      data: [],//[22, 32, 15, 23, 60],
      type: 'bar',
      barWidth: '12',
      lineStyle: {
        color: '#00FFD4',
      },
      stack: 'total',
      label: {
        show: true,
        position: "top",
        color: '#00E5B4'
      },
      itemStyle: {
        normal: {
          barBorderRadius: [0, 3, 3, 0]
        }
      },
    },
    {
      name: '',// '秸秆',
      data: [],//[22, 32, 15, 23, 45],
      type: 'bar',
      barWidth: '12',
      lineStyle: {
        color: '#00FFD4',
      },
      stack: 'total',
      label: {
        show: true,
        position: "top",
        color: '#00E5B4'
      },
      itemStyle: {
        normal: {
          barBorderRadius: [0, 3, 3, 0]
        }
      },
    }
  ]
}
