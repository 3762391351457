<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Pancras
 * @LastEditTime: 2023-02-03 18:10:08
-->
<template>
  <div flex="cross:center">
    <div id="mapDiv" />
  </div>
</template>
<script>
import { _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import util from '@/libs/util'

export default {
  props: {
    markState: {
      type: String,
      default: '1'
    }
  },

  data () {
    return {
      map: null,//地图对象
      T: null,//地图实例
      markerList: [],//点数组
      infoWin: null,//信息窗口
      screenHeight: document.body.clientHeight, // 屏幕高度
      plantDataA: [],//瞭望塔
      plantDataB: [],//古树名木保护
      plantDataC: [],//林班
      cottonDataA: [],//林场分布
      cottonDataB: [],//香梨小众水果分布
      cottonDataC: [],//退化林修复
      cottonDataD: [],//退耕还林还草
      cottonDataE: [],//天然草场
      cottonDataF: [],//人工草场
      cottonDataG: [],//野生动物
      cottonDataK: [],//野生植物
    }
  },

  mounted () {
    this.T = window.T
    var imageURL = 'http://t0.tianditu.gov.cn/img_w/wmts?' +
      'SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles' +
      '&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=0e37d14abb5af6aa4caa68578e439c2e'
    // TileLayer添加图层所用的构造函数
    var lay = new this.T.TileLayer(imageURL, { minZoom: 10, maxZoom: 20 }) // 地图图层
    var config = { layers: [lay] }

    this.map = new this.T.Map('mapDiv', config) // 地图实例
    this.map.centerAndZoom(new this.T.LngLat(86.16064373535157, 41.71617988264258), 12)
    // //允许鼠标双击放大地图
    this.map.enableScrollWheelZoom()
    this.map.setMapType(window.TMAP_HYBRID_MAP) // 设置地图位地星混合图层

    this.getPlantDataA()//棉蛋mark


    /*公布方法 */

    //关闭信息窗口
    window.onCloseInfoWindow = () => {
      this.onCloseInfoWindow()
    }
  },

  watch: {
    markState (val) {
      this.map.clearOverLays();
      this.markerList = []
      if (val === '1') {
        this.getPlantDataA()//mark
      }

      if (val === '2') {
        this.getPlantDataB()//mark
      }

      if (val === '3') {
        this.getPlantDataC()//mark
      }

      if (val === '4') {
        this.getCottonDataA()//mark
      }

      if (val === '5') {
        this.getCottonDataB()//mark
      }

      if (val === '6') {
        this.getCottonDataC()//mark
      }

      if (val === '7') {
        this.getCottonDataD()//mark
      }

      if (val === '8') {
        this.getCottonDataE()//mark
      }

      if (val === '9') {
        this.getCottonDataF()//mark
      }

      if (val === '10') {
        this.getCottonDataG()//mark
      }

      if (val === '11') {
        this.getCottonDataK()//mark
      }
    }
  },

  methods: {
    /*棉蛋mark */
    getPlantDataA () {
      _GetAspScreenComponentData('NEWKEL-DT-LWT').then(res => {
        if (res) {
          const arr2 = []
          res.data.data.forEach(item => {
            arr2.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              name3: item.data_namec,
              msg3: item.data_msgc,
              value: item.data_value,
              url: item.data_url
            })
          })
          this.plantDataA = arr2
          this.onPlantA()
          // 点位集合获取所有点位中间位置
          const latlng = arr2.length > 1 ? util.getPolygonCenter(arr2) : arr2[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)
        }
      })
    },

    onPlantA () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/gsbh.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.plantDataA.forEach(item => {

        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='width: 100%;display:flex;justify-content: space-between;margin:20px 0 0 0'>" +

          "<div style='width: 43%'>" +
          "<div style='width: 100%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;width:80px'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width:150px;text-align: right'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 100%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 100%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9'>" + item.name3 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7'>" + item.msg3 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +
          "</div>" +

          "<div style='width: 45%;height: 130px;'><img src ='" + item.url + "'style='width:100%;height:100%'> </div>" +

          "</div>" +
          "</div>" +
          "<div style='margin:15px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;display:-webkit-box;-webkit-line-clamp: 3;-webkit-box-orient:vertical;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true, // 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
          // },
        })
      })
    },

    getPlantDataB () {
      _GetAspScreenComponentData('NEKWEL-DT-GSMUBH').then(res => {
        if (res) {
          const arr2 = []
          res.data.data.forEach(item => {
            arr2.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              name3: item.data_namec,
              msg3: item.data_msgc,
              value: item.data_value,
              url: item.data_url
            })
          })
          this.plantDataB = arr2
          this.onPlantB()
          // 点位集合获取所有点位中间位置
          const latlng = arr2.length > 1 ? util.getPolygonCenter(arr2) : arr2[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)
        }
      })
    },

    onPlantB () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/mmbh.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.plantDataB.forEach(item => {

        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='width: 100%;display:flex;justify-content: space-between;margin:20px 0 0 0'>" +

          "<div style='width: 43%'>" +
          "<div style='width: 100%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;width:80px'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width:150px;text-align: right'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 100%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 100%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9'>" + item.name3 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7'>" + item.msg3 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +
          "</div>" +

          "<div style='width: 45%;height: 130px;'> <img src ='" + item.url + "'style='width:100%;height:100%'> </div>" +

          "</div>" +
          "</div>" +
          "<div style='margin:19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;display:-webkit-box;-webkit-line-clamp: 3;-webkit-box-orient:vertical;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true, // 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
          // },
        })
      })
    },

    getPlantDataC () {
      _GetAspScreenComponentData('NEWKEL-DT-LB').then(res => {
        if (res) {
          const arr2 = []
          res.data.data.forEach(item => {
            arr2.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              name3: item.data_namec,
              msg3: item.data_msgc,
              value: item.data_value,
              url: item.data_url
            })
          })
          this.plantDataC = arr2
          this.onPlantC()
          // 点位集合获取所有点位中间位置
          const latlng = arr2.length > 1 ? util.getPolygonCenter(arr2) : arr2[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)
        }
      })
    },

    onPlantC () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/lbdw.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.plantDataC.forEach(item => {

        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='width: 100%;display:flex;justify-content: space-between;margin:20px 0 0 0'>" +

          "<div style='width: 43%'>" +
          "<div style='width: 100%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 100%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 100%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;width:80px'>" + item.name3 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width:150px;text-align: right'>" + item.msg3 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +
          "</div>" +

          "<div style='width: 45%;height: 130px;'> <img src ='" + item.url + "'style='width:100%;height:100%'> </div>" +

          "</div>" +
          "</div>" +
          "<div style='margin:19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;display:-webkit-box;-webkit-line-clamp: 3;-webkit-box-orient:vertical;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true, // 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
          // },
        })
      })
    },
    getCottonDataA () {
      // 天然草场
      _GetAspScreenComponentData('KELNEW-NY-TRCC').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {

            arr1.push({
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value,
              geoInfo: item.geos.split('\n').map(geo => {
                let lngLat = geo.split(',')
                return { lng: Number(lngLat[0]), lat: Number(lngLat[1]) }
              })
            })
          })
          this.cottonDataA = arr1
          this.onCottonA()
        }
      })
    },
    /*第一个 */
    onCottonA () {
      var points = [];

      this.cottonDataA.forEach(item => {
        const pointsData = []

        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#13c081", weight: 1, opacity: 0.9, fillColor: "#ade4d7", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          e.conent = item
          _this.onLandMesA(e)
        });
      });
    },

    onLandMesA (e) {


      let latlng = util.getPolygonCenter(e.conent.geoInfo)

      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div style='width: 100%;color:#fff'>" +

        "<div style='margin: 15px auto;width:100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;width:80px'>" + e.conent.name1 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + e.conent.msg1 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name2 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg2 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
        e.conent.value +
        "</div>" +

        "</div>";

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true, // 开启点击地图关闭弹窗
        offset: new _this.T.Point(20, 0)
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
      console.log(point);
    },
    //香梨
    getCottonDataB () {
      const bd = new this.T.LngLatBounds(new this.T.LngLat(85.0481562940053, 41.275457458956), new this.T.LngLat(86.70307142356025, 41.95717303712637))
      const fbimg = new this.T.ImageOverlay('http://8.142.105.233:6080/arcgis/rest/services/ZZGJMapService/MapServer/export?dpi=96&transparent=true&format=png8&bbox=85.0481562940053%2C41.275457458956%2C86.70307142356025%2C41.95717303712637&bboxSR=4326&imageSR=4326&size=1391%2C573&f=image', bd, {
        opacity: 1,
        alt: ''
      })
      this.map.addOverLay(fbimg)
      this.map.panTo(new this.T.LngLat(85.71533, 41.64213), [10])
      // _GetAspScreenComponentData('NEWKEL-DT-XLXZSGFB').then(res => {
      //   if (res) {
      //     const arr1 = []
      //     res.data.data.forEach(item => {
      //       
      //       arr1.push({
      //         title: item.data_title,
      //         name1: item.data_namea,
      //         msg1: item.data_msga,
      //         name2: item.data_nameb,
      //         msg2: item.data_msgb,
      //         value: item.data_value,
      //         geoInfo: [
      //           { lng: Number(item.data_lng), lat: Number(item.data_lat) },
      //           { lng: Number(item.data_lnga), lat: Number(item.data_lata) },
      //           { lng: Number(item.data_lngb), lat: Number(item.data_latb) },
      //           { lng: Number(item.data_lngc), lat: Number(item.data_latc) }
      //         ]
      //       })
      //     })
      //     this.cottonDataB = arr1
      //     this.onCottonB()
      //   }
      // })
    },
    /*第一个 */
    onCottonB () {
      var points = [];

      this.cottonDataB.forEach(item => {
        const pointsData = []

        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#00b5fd", weight: 1, opacity: 0.9, fillColor: "#b8ecd9", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          e.conent = item
          _this.onLandMesB(e)
        });
      });
    },

    onLandMesB (e) {


      let latlng = util.getPolygonCenter(e.conent.geoInfo)

      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div style='width: 100%;color:#fff'>" +

        "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name1 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg1 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name2 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg2 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='margin: 19px auto;width:100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
        e.conent.value +
        "</div>" +

        "</div>";

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true, // 开启点击地图关闭弹窗
        offset: new _this.T.Point(20, 0)
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
      console.log(point);
    },
    //退化林修复
    getCottonDataC () {
      _GetAspScreenComponentData('KELNEW-NY-THLXF').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {

            arr1.push({
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value,
              geoInfo: item.geos.split('\n').map(geo => {
                let lngLat = geo.split(',')
                return { lng: Number(lngLat[0]), lat: Number(lngLat[1]) }
              })
            })
          })
          this.cottonDataC = arr1
          this.onCottonC()
        }
      })
    },
    /*第一个 */
    onCottonC () {
      var points = [];

      this.cottonDataC.forEach(item => {
        const pointsData = []

        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#6a8cfb", weight: 1, opacity: 0.9, fillColor: "#a7dbc8", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          e.conent = item
          _this.onLandMesC(e)
        });
      });
    },

    onLandMesC (e) {


      let latlng = util.getPolygonCenter(e.conent.geoInfo)

      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div style='width: 100%;color:#fff'>" +

        "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;width:80px'>" + e.conent.name1 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;text-overflow: ellipsis'>" + e.conent.msg1 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name2 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg2 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
        e.conent.value +
        "</div>" +

        "</div>";

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true, // 开启点击地图关闭弹窗
        offset: new _this.T.Point(20, 0)
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
      console.log(point);
    },
    //退耕还林
    getCottonDataD () {
      _GetAspScreenComponentData('KELNEW-NY-TGHLHC').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {

            arr1.push({
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value,
              geoInfo: item.geos.split('\n').map(geo => {
                let lngLat = geo.split(',')
                return { lng: Number(lngLat[0]), lat: Number(lngLat[1]) }
              })
            })
          })
          this.cottonDataD = arr1
          this.onCottonD()
        }
      })
    },
    /*第一个 */
    onCottonD () {
      var points = [];

      this.cottonDataD.forEach(item => {
        const pointsData = []

        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#89ff82", weight: 1, opacity: 0.9, fillColor: "#a7dbc8", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          e.conent = item
          _this.onLandMesD(e)
        });
      });
    },

    onLandMesD (e) {


      let latlng = util.getPolygonCenter(e.conent.geoInfo)

      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div style='width: 100%;color:#fff'>" +

        "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;width:80px'>" + e.conent.name1 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;;white-space: nowrap;overflow: hidden;text-overflow: ellipsis'>" + e.conent.msg1 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name2 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg2 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
        e.conent.value +
        "</div>" +

        "</div>";

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true // 开启点击地图关闭弹窗
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
      console.log(point);
    },
    getCottonDataE () {
      // 林场分布
      _GetAspScreenComponentData('KELNEW-NY-LCFB').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {

            arr1.push({
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value,
              geoInfo: item.geos.split('\n').map(geo => {
                let lngLat = geo.split(',')
                return { lng: Number(lngLat[0]), lat: Number(lngLat[1]) }
              })
            })
          })
          this.cottonDataE = arr1
          this.onCottonE()
        }
      })
    },
    /*第一个 */
    onCottonE () {
      var points = [];

      this.cottonDataE.forEach(item => {
        const pointsData = []

        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#13c081", weight: 1, opacity: 0.9, fillColor: "#a7dbc8", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          e.conent = item
          _this.onLandMesE(e)
        });
      });
    },

    onLandMesE (e) {


      let latlng = util.getPolygonCenter(e.conent.geoInfo)

      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div style='width: 100%;color:#fff'>" +

        "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name1 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg1 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name2 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg2 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
        e.conent.value +
        "</div>" +

        "</div>";

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true // 开启点击地图关闭弹窗
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
      console.log(point);
    },
    //人工草场
    getCottonDataF () {
      _GetAspScreenComponentData('KELNEW-NY-RGCC').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {

            arr1.push({
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value,
              geoInfo: item.geos.split('\n').map(geo => {
                let lngLat = geo.split(',')
                return { lng: Number(lngLat[0]), lat: Number(lngLat[1]) }
              })
            })
          })
          this.cottonDataF = arr1
          this.onCottonF()
        }
      })
    },
    /*第一个 */
    onCottonF () {
      var points = [];

      this.cottonDataF.forEach(item => {
        const pointsData = []

        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#f52cff", weight: 1, opacity: 0.9, fillColor: "#a7dbc8", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          e.conent = item
          _this.onLandMesF(e)
        });
      });
    },

    onLandMesF (e) {


      let latlng = util.getPolygonCenter(e.conent.geoInfo)

      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div style='width: 100%;color:#fff'>" +

        "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name1 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg1 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name2 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg2 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
        e.conent.value +
        "</div>" +

        "</div>";

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true // 开启点击地图关闭弹窗
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
      console.log(point);
    },
    //野生动物
    getCottonDataG () {
      _GetAspScreenComponentData('KELNEW-NY-TYYSDW').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {

            arr1.push({
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value,
              geoInfo: item.geos.split('\n').map(geo => {
                let lngLat = geo.split(',')
                return { lng: Number(lngLat[0]), lat: Number(lngLat[1]) }
              })
            })
          })
          this.cottonDataG = arr1
          this.onCottonG()
        }
      })
    },
    /*第一个 */
    onCottonG () {
      var points = [];

      this.cottonDataG.forEach(item => {
        const pointsData = []

        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#c0be13", weight: 1, opacity: 0.9, fillColor: "#ade4d7", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          e.conent = item
          _this.onLandMesG(e)
        });
      });
    },

    onLandMesG (e) {


      let latlng = util.getPolygonCenter(e.conent.geoInfo)

      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div style='width: 100%;color:#fff'>" +

        "<div style='margin: 15px auto;width:100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name1 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg1 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name2 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg2 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
        e.conent.value +
        "</div>" +

        "</div>";

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true // 开启点击地图关闭弹窗
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
      console.log(point);
    },
    //野生植物
    getCottonDataK () {
      _GetAspScreenComponentData('KELNEW-NY-TYYSZW').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {

            arr1.push({
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value,
              geoInfo: item.geos.split('\n').map(geo => {
                let lngLat = geo.split(',')
                return { lng: Number(lngLat[0]), lat: Number(lngLat[1]) }
              })
            })
          })
          this.cottonDataK = arr1
          this.onCottonK()
        }
      })
    },
    /*第一个 */
    onCottonK () {
      var points = [];

      this.cottonDataK.forEach(item => {
        const pointsData = []

        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#13c081", weight: 1, opacity: 0.9, fillColor: "#ade4d7", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          e.conent = item
          _this.onLandMesH(e)
        });
      });
    },

    onLandMesH (e) {


      let latlng = util.getPolygonCenter(e.conent.geoInfo)

      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div style='width: 100%;color:#fff'>" +

        "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name1 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg1 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name2 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg2 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
        e.conent.value +
        "</div>" +

        "</div>";

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true // 开启点击地图关闭弹窗
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
      console.log(point);
    },
    /*关闭信息窗口 */
    onCloseInfoWindow () {
      this.infoWin.closeInfoWindow()
    }
  }
}
</script>
<style>
.tdt-infowindow-content-wrapper,
.tdt-infowindow-tip {
  background: rgba(0, 38, 25, 0.66);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5),
    inset 0px 4px 10px 0px rgba(163, 255, 227, 0.4);
  border: 1px solid #008166;
  backdrop-filter: blur(6px);
}

.tdt-container a.tdt-infowindow-close-button {
  color: #fff;
}
</style>
<style scoped lang="scss">
#mapDiv {
  width: 2560px;
  height: 1080px;
}
</style>
