<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Pancras
 * @LastEditTime: 2023-02-03 18:25:11
-->
<template>
  <div flex="cross:center">
    <div id="mapDiv" />
    <div>
      <cottonPopu :cotton-egg-params="cottonEggParams" />
    </div>
    <div>
      <plantPopu :plant-params="plantParams" />
    </div>
    <div>
      <vehiclePopu :vehicle-id-sell="vehicleId_Sell"
                   @sellDrawer="getSellDrawer"
                   :vehicle-id-gather="vehicleId_Gather" />
    </div>
  </div>
</template>
<script>
import cottonPopu from './components/cottonEggPopu'
import vehiclePopu from './components/vehiclePopu'
import plantPopu from './components/plantPopo'
import { _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import util from '@/libs/util'
export default {
  props: {
    markState: {
      type: String,
      default: '1'
    }
  },
  components: {
    cottonPopu,
    plantPopu,
    vehiclePopu
  },
  data () {
    return {
      map: null,//地图对象
      T: null,//地图实例
      markerList: [],//点数组
      infoWin: null,//信息窗口
      screenHeight: document.body.clientHeight, // 屏幕高度
      cottonTwoData: [],
      cottonTwoDataA: [],
      cottonTwoDataB: [],
      cottonTwoDataC: [],
      cottonTwoDataD: [],
      vehicleId_Sell: null,//车辆id查询交售
      vehicleId_Gather: null,//车辆id查询采收
      marker: null,
      cottonEggParams: {},//当前点击棉蛋参数
      plantParams: {},//当前点击工厂参数
    }
  },

  watch: {
    markState (val) {
      this.map.clearOverLays();
      this.markerList = []
      if (val === '1') {
        this.getCottonTwoData()//mark
      }

      if (val === '2') {
        this.getCottonTwoDataA()//mark
      }

      if (val === '3') {
        this.getCottonTwoDataB()//mark
      }

      if (val === '4') {
        this.getCottonTwoDataC()//mark
      }

      if (val === '5') {
        this.getCottonTwoDataD()//mark
      }
    }
  },

  mounted () {
    this.T = window.T
    var imageURL = 'http://t0.tianditu.gov.cn/img_w/wmts?' +
      'SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles' +
      '&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=0e37d14abb5af6aa4caa68578e439c2e'
    // TileLayer添加图层所用的构造函数
    var lay = new this.T.TileLayer(imageURL, { minZoom: 10, maxZoom: 20 }) // 地图图层
    var config = { layers: [lay] }

    this.map = new this.T.Map('mapDiv', config) // 地图实例
    this.map.centerAndZoom(new this.T.LngLat(86.16064373535157, 41.71617988264258), 12)
    // //允许鼠标双击放大地图
    this.map.enableScrollWheelZoom()
    this.map.setMapType(window.TMAP_HYBRID_MAP) // 设置地图位地星混合图层

    this.getCottonTwoData()//mark
    // this.getCotton()//棉蛋
    // this.getPlant()//工厂
    // this.getVehicle()//获取车辆

    //关闭信息窗口
    window.onCloseInfoWindow = () => {
      this.onCloseInfoWindow()
    }

    window.onCottonFarmers = () => {
      this.onCottonFarmers()
    };

    window.onCottonTransport = () => {
      this.onCottonTransport()
    };

    window.onCottonSell = () => {
      this.onCottonSell()
    };

    window.onPlantPrice = () => {
      this.onPlantPrice()
    };

    window.onPlantExamine = () => {
      this.onPlantExamine()
    };

    window.onPlantSell = () => {
      this.onPlantSell()
    };

    window.onProcess = () => {
      this.onProcess()
    };

    window.onVehicleGatherDetails = () => {
      this.onVehicleGatherDetails()
    };

    window.onVehicleSellDetails = () => {
      this.onVehicleSellDetails()
    };
  },

  methods: {
    /*获取棉蛋数据*/
    getCotton () {
      let arr = [
        {
          lng: 85.99,
          lat: 41.72,
        },
        {
          lng: 85.99,
          lat: 41.71,
        },
        {
          lng: 85.99,
          lat: 41.70,
        },
        {
          lng: 85.99,
          lat: 41.69,
        },
        {
          lng: 85.99,
          lat: 41.68,
        },
        {
          lng: 85.99,
          lat: 41.67,
        },
        {
          lng: 85.98,
          lat: 41.72,
        },
        {
          lng: 85.98,
          lat: 41.71,
        },
        {
          lng: 85.98,
          lat: 41.70,
        },
        {
          lng: 85.98,
          lat: 41.69,
        },
        {
          lng: 85.98,
          lat: 41.68,
        },
        {
          lng: 85.98,
          lat: 41.67,
        },
        {
          lng: 85.97,
          lat: 41.72,
        },
        {
          lng: 85.97,
          lat: 41.71,
        },
        {
          lng: 85.97,
          lat: 41.70,
        },
        {
          lng: 85.97,
          lat: 41.69,
        },
        {
          lng: 85.97,
          lat: 41.68,
        },
        {
          lng: 85.97,
          lat: 41.67,
        },
        {
          lng: 85.96,
          lat: 41.72,
        },
        {
          lng: 85.96,
          lat: 41.71,
        },
        {
          lng: 85.96,
          lat: 41.70,
        },
        {
          lng: 85.96,
          lat: 41.69,
        },
        {
          lng: 85.96,
          lat: 41.68,
        },
        {
          lng: 85.96,
          lat: 41.67,
        }
      ]
      arr.forEach(item => {
        const icon = new this.T.Icon({
          iconUrl: '/image/icon/md.png',
          id: 'test',
          iconSize: new this.T.Point(50, 62),
          iconAnchor: new this.T.Point(0, 0)
        })
        this.marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(this.marker)
        this.map.addOverLay(this.marker);
        this.marker.on('click', this.cottonmarkerClick);
      })
    },

    /*点击棉蛋mark */
    cottonmarkerClick (e) {
      let lnglat = e.lnglat
      let _this = this
      _this.map.panTo(lnglat, 12)
      let content = "<div>" +
        "<div style='margin: 8px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px; 0'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #fff;'>棉蛋信息</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px; 0'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>采收时间</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>2023-9-3</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px; 0'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>采收时间</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>" + '北纬' + e.lnglat.lat + '东经' + e.lnglat.lng + "</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px; 0'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>农户信息</div>" +
        "<div onClick='onCottonFarmers()' style='font-size: 14px;font-weight: 400;color: #ffffff;cursor: pointer;'>详情></div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px; 0'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>拉运信息</div>" +
        "<div onClick='onCottonTransport()' style='font-size: 14px;font-weight: 400;color: #ffffff;cursor: pointer;'>详情></div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px; 0'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>交售信息</div>" +
        "<div onClick='onCottonSell()' style='font-size: 14px;font-weight: 400;color: #ffffff;cursor: pointer;'>详情></div>" +
        "</div>" +
        "</div>"

      var point = lnglat
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '360',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true,// 开启点击地图关闭弹窗
        offset: new _this.T.Point(20, 0)
      }); // 创建信息窗口对象
      _this.infoWin.setContent(content);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
    },

    /*获取工厂数据 */
    getPlant () {
      let arr = [
        {
          lng: 86.08,
          lat: 41.77,
        },
        {
          lng: 86.05,
          lat: 41.75,
        },
        {
          lng: 86.03,
          lat: 41.72,
        },
        {
          lng: 86.12,
          lat: 41.75,
        },
        {
          lng: 86.11,
          lat: 41.76,
        },
        {
          lng: 86.07,
          lat: 41.79,
        },
        {
          lng: 86.10,
          lat: 41.81,
        },
        {
          lng: 86.10,
          lat: 41.75,
        },
        {
          lng: 86.10,
          lat: 41.73,
        },
        {
          lng: 86.12,
          lat: 41.70,
        },
      ]
      arr.forEach(item => {
        const icon = new this.T.Icon({
          iconUrl: '/image/icon/yhc.png',
          id: 'test',
          iconSize: new this.T.Point(50, 62),
          iconAnchor: new this.T.Point(0, 0)
        })
        this.marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(this.marker)
        this.map.addOverLay(this.marker);
        this.marker.on('click', this.plantmarkerClick);
      })
    },

    /*点击工厂mark */
    plantmarkerClick (e) {
      let lnglat = e.lnglat
      let _this = this
      _this.map.panTo(lnglat, 12)
      let content = "<div>" +
        "<div style='margin: 10px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #fff;'>工厂信息</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;width:130px'>轧花厂</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>乌鲁木齐轧花厂</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>今日牌价</div>" +
        "<div onClick='onPlantPrice()' style='font-size: 14px;font-weight: 400;color: #ffffff;cursor: pointer;'>" + '¥' + 200 + '>' + "</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>公检记录</div>" +
        "<div onClick='onPlantExamine()' style='font-size: 14px;font-weight: 400;color: #ffffff;cursor: pointer;'>" + 10 + '批' + '>' + "</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>收购记录</div>" +
        "<div onClick='onPlantSell()' style='font-size: 14px;font-weight: 400;color: #ffffff;cursor: pointer;'>" + 5 + '包' + '>' + "</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding: 8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>加工记录</div>" +
        "<div onClick='onProcess()' style='font-size: 14px;font-weight: 400;color: #ffffff;cursor: pointer;'>" + 20 + '包' + '>' + "</div>" +
        "</div>"

      var point = lnglat
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '360',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true,// 开启点击地图关闭弹窗
        offset: new _this.T.Point(20, 0)
      }); // 创建信息窗口对象
      _this.infoWin.setContent(content);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
    },

    /*获取车辆数据 */
    getVehicle () {
      let arr = [
        {
          lng: 86.00,
          lat: 41.73,
        },
        {
          lng: 86.05,
          lat: 41.73,
        },
        {
          lng: 86.02,
          lat: 41.75,
        },
        {
          lng: 86.01,
          lat: 41.78,
        },
        {
          lng: 86.00,
          lat: 41.80,
        },
        {
          lng: 86.05,
          lat: 41.78,
        },
        {
          lng: 86.10,
          lat: 41.78,
        },
        {
          lng: 86.15,
          lat: 41.80,
        },
        {
          lng: 86.18,
          lat: 41.79,
        },
        {
          lng: 86.16,
          lat: 41.75,
        },
        {
          lng: 86.10,
          lat: 41.77,
        },
        {
          lng: 86.05,
          lat: 41.70,
        },
        {
          lng: 86.20,
          lat: 41.79,
        },
        {
          lng: 86.23,
          lat: 41.75,
        },
        {
          lng: 86.26,
          lat: 41.73,
        },
        {
          lng: 86.15,
          lat: 41.70,
        },
        {
          lng: 86.20,
          lat: 41.80,
        },
      ]
      arr.forEach((item, index) => {
        let markIcon = ''
        if (index != 6) {
          markIcon = '/image/icon/nj.png'
        } else {
          markIcon = 'https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/7930393403b34d86a366e0f7209f87b8.png'
        }
        const icon = new this.T.Icon({
          iconUrl: markIcon,
          id: 'test',
          iconSize: new this.T.Point(50, 62),
          iconAnchor: new this.T.Point(0, 0)
        })
        this.marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(this.marker)
        this.map.addOverLay(this.marker);
        this.marker.on('click', this.vehiclemarkerClick);
      })
    },

    /*点击车辆mark */
    vehiclemarkerClick (e) {
      let lnglat = e.lnglat
      let _this = this
      _this.map.panTo(lnglat, 12)
      let content = "<div>" +
        "<div style='margin: 14px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #fff;'>车辆信息</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>终端设备号</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>" + 205521 + "</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;width:150px'>当前具体位置</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>库尔勒</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>农机牌号</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>新A 90cs</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>车主姓名</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>张三</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>车主手机号</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>15869588589</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>速度</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>20</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>车机类型</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>农机</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div onClick='onVehicleGatherDetails()' style='font-size: 14px;font-weight: bold;color: #ffffff;'>采收记录</div>" +
        "<div onClick='onVehicleGatherDetails()' style='font-size: 14px;font-weight: 400;color: #ffffff;cursor: pointer;'>详情></div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div onClick='onVehicleSellDetails()' style='font-size: 14px;font-weight: bold;color: #ffffff;'>交售记录</div>" +
        "<div onClick='onVehicleSellDetails()' style='font-size: 14px;font-weight: 400;color: #ffffff;cursor: pointer;'>详情></div>" +
        "</div>" +
        "</div>"

      var point = lnglat
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '360',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true,// 开启点击地图关闭弹窗
        offset: new _this.T.Point(20, 0)
      }); // 创建信息窗口对象
      _this.infoWin.setContent(content);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
    },

    /*打开二级窗口 */
    onVehicleSellDetails () {
      let state = Math.round(Math.random() * 100)//随机数
      this.vehicleId_Sell = state
    },

    /*打开二级窗口 */
    onVehicleGatherDetails () {
      let state = Math.round(Math.random() * 100)//随机数
      this.vehicleId_Gather = state
    },

    //接收车辆弹窗返回参数
    getSellDrawer (val) {
      console.log(val)
      this.vehicleId_Sell = null
      this.vehicleId_Gather = null
    },

    /*点击工厂今日牌价 */
    onPlantPrice () {
      let state = Math.round(Math.random() * 100)//随机数
      let params = {
        id: state,
        type: 'price'
      }
      this.plantParams = params
    },

    /*点击工厂公检信息 */
    onPlantExamine () {
      let state = Math.round(Math.random() * 100)//随机数
      let params = {
        id: state,
        type: 'examine'
      }
      this.plantParams = params
    },

    /*点击工厂收购信息 */
    onPlantSell () {
      let state = Math.round(Math.random() * 100)//随机数
      let params = {
        id: state,
        type: 'sell'
      }
      this.plantParams = params
    },


    /*点击工厂加工信息 */
    onProcess () {
      let state = Math.round(Math.random() * 100)//随机数
      let params = {
        id: state,
        type: 'process'
      }
      this.plantParams = params
    },

    onCottonFarmers () {
      let state = Math.round(Math.random() * 100)//随机数
      let params = {
        type: 'farmer',
        id: state
      }
      this.cottonEggParams = params
    },

    onCottonTransport () {
      let state = Math.round(Math.random() * 100)//随机数
      let params = {
        type: 'transport',
        id: state
      }
      this.cottonEggParams = params
    },

    onCottonSell () {
      let state = Math.round(Math.random() * 100)//随机数
      let params = {
        type: 'sell',
        id: state
      }
      this.cottonEggParams = params
    },

    getCottonTwoData () {
      _GetAspScreenComponentData('NEWKEL-DT-JSGGXT').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value
            })
          })
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)

          var bounds = this.map.getBounds();
          var sw = bounds.getSouthWest();
          var ne = bounds.getNorthEast();
          var lngSpan = Math.abs(sw.lng - ne.lng);
          var latSpan = Math.abs(ne.lat - sw.lat);
          var randomPoints = []
          for (var i = 0; i < 15; i++) {
            //创建图片对象
            // 点位经纬度坐标
            const point = { lng: sw.lng + lngSpan * (Math.random() * 0.7), lat: ne.lat - latSpan * (Math.random() * 0.7) }
            randomPoints.push(point)
          }

          randomPoints.map(item => {
            item.title = arr4[0].title
            item.name1 = arr4[0].name1
            item.msg1 = arr4[0].msg1
            item.name2 = arr4[0].name2
            item.msg2 = arr4[0].msg2
            item.value = arr4[0].value
            return item
          })
          arr4.push(...randomPoints)
          this.cottonTwoData = arr4
          this.onCottonTwo()
        }
      })
    },
    onCottonTwo () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/jsgg.png',
        id: 'test',
        iconSize: new this.T.Point(30, 37),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonTwoData.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true, // 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
        })
      })
    },

    getCottonTwoDataA () {
      _GetAspScreenComponentData('NEWKEL-DT-ZNGSSB').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value
            })
          })
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)

          var bounds = this.map.getBounds();
          var sw = bounds.getSouthWest();
          var ne = bounds.getNorthEast();
          var lngSpan = Math.abs(sw.lng - ne.lng);
          var latSpan = Math.abs(ne.lat - sw.lat);
          var randomPoints = []
          for (var i = 0; i < 15; i++) {
            //创建图片对象
            // 点位经纬度坐标
            const point = { lng: sw.lng + lngSpan * (Math.random() * 0.7), lat: ne.lat - latSpan * (Math.random() * 0.7) }
            randomPoints.push(point)
          }

          randomPoints.map(item => {
            item.title = arr4[0].title,
              item.name1 = arr4[0].name1
            item.msg1 = arr4[0].msg1
            item.name2 = arr4[0].name2
            item.msg2 = arr4[0].msg2
            item.value = arr4[0].value
            return item
          })
          arr4.push(...randomPoints)
          this.cottonTwoDataA = arr4
          this.onCottonTwoA()
        }
      })
    },
    onCottonTwoA () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/zngs.png',
        id: 'test',
        iconSize: new this.T.Point(30, 37),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonTwoDataA.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true, // 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
        })
      })
    },

    getCottonTwoDataB () {
      _GetAspScreenComponentData('NEWKEL-DT-WSCLC').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value
            })
          })
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)

          var bounds = this.map.getBounds();
          var sw = bounds.getSouthWest();
          var ne = bounds.getNorthEast();
          var lngSpan = Math.abs(sw.lng - ne.lng);
          var latSpan = Math.abs(ne.lat - sw.lat);
          var randomPoints = []
          for (var i = 0; i < 15; i++) {
            //创建图片对象
            // 点位经纬度坐标
            const point = { lng: sw.lng + lngSpan * (Math.random() * 0.7), lat: ne.lat - latSpan * (Math.random() * 0.7) }
            randomPoints.push(point)
          }

          randomPoints.map(item => {
            item.title = arr4[0].title,
              item.name1 = arr4[0].name1
            item.msg1 = arr4[0].msg1
            item.name2 = arr4[0].name2
            item.msg2 = arr4[0].msg2
            item.value = arr4[0].value
            return item
          })
          arr4.push(...randomPoints)
          this.cottonTwoDataB = arr4
          this.onCottonTwoB()
        }
      })
    },
    onCottonTwoB () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/wscl.png',
        id: 'test',
        iconSize: new this.T.Point(30, 37),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonTwoDataB.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true, // 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
        })
      })
    },


    getCottonTwoDataC () {
      _GetAspScreenComponentData('NEWKEL-DT-BF').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value
            })
          })
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)

          var bounds = this.map.getBounds();
          var sw = bounds.getSouthWest();
          var ne = bounds.getNorthEast();
          var lngSpan = Math.abs(sw.lng - ne.lng);
          var latSpan = Math.abs(ne.lat - sw.lat);
          var randomPoints = []
          for (var i = 0; i < 15; i++) {
            //创建图片对象
            // 点位经纬度坐标
            const point = { lng: sw.lng + lngSpan * (Math.random() * 0.7), lat: ne.lat - latSpan * (Math.random() * 0.7) }
            randomPoints.push(point)
          }

          randomPoints.map(item => {
            item.title = arr4[0].title,
              item.name1 = arr4[0].name1
            item.msg1 = arr4[0].msg1
            item.name2 = arr4[0].name2
            item.msg2 = arr4[0].msg2
            item.value = arr4[0].value
            return item
          })
          arr4.push(...randomPoints)
          this.cottonTwoDataC = arr4
          this.onCottonTwoC()
        }
      })
    },
    onCottonTwoC () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/bf.png',
        id: 'test',
        iconSize: new this.T.Point(30, 37),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonTwoDataC.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true, // 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
        })
      })
    },

    getCottonTwoDataD () {
      _GetAspScreenComponentData('NEWKEL-DT-YL').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value
            })
          })
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)

          var bounds = this.map.getBounds();
          var sw = bounds.getSouthWest();
          var ne = bounds.getNorthEast();
          var lngSpan = Math.abs(sw.lng - ne.lng);
          var latSpan = Math.abs(ne.lat - sw.lat);
          var randomPoints = []
          for (var i = 0; i < 15; i++) {
            //创建图片对象
            // 点位经纬度坐标
            const point = { lng: sw.lng + lngSpan * (Math.random() * 0.7), lat: ne.lat - latSpan * (Math.random() * 0.7) }
            randomPoints.push(point)
          }

          randomPoints.map(item => {
            item.title = arr4[0].title,
              item.name1 = arr4[0].name1
            item.msg1 = arr4[0].msg1
            item.name2 = arr4[0].name2
            item.msg2 = arr4[0].msg2
            item.value = arr4[0].value
            return item
          })
          arr4.push(...randomPoints)
          this.cottonTwoDataD = arr4
          this.onCottonTwoD()
        }
      })
    },
    onCottonTwoD () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/dbs.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonTwoDataD.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true, // 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
        })
      })
    },

    /*关闭信息窗口 */
    onCloseInfoWindow () {
      this.infoWin.closeInfoWindow()
    }
  }
}
</script>
<style>
.tdt-infowindow-content-wrapper,
.tdt-infowindow-tip {
  background: rgba(0, 38, 25, 0.66);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5),
    inset 0px 4px 10px 0px rgba(163, 255, 227, 0.4);
  border: 1px solid #008166;
  backdrop-filter: blur(6px);
}

.tdt-container a.tdt-infowindow-close-button {
  color: #fff;
}
</style>
<style scoped lang="scss">
#mapDiv {
  z-index: 997;
  width: 2560px;
  height: 1080px;
  position: fixed;
  left: 0;
  top: calc(50% - 540px);
}
</style>
