export default {
  color: [{
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#5883FF' // 0% 处的颜色
    }, {
      offset: 1, color: '#51BAFF' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }, {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#00E5B4' // 0% 处的颜色
    }, {
      offset: 1, color: '#AEFFE2' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }, {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#AADE55' // 0% 处的颜色
    }, {
      offset: 1, color: '#F9FFAE' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }],
  legend: {
    data: [],//['本地', '外地进口'],
    textStyle: {
      color: '#FFFFFF'
    },
    padding: [
      20,  // 上
      0, // 右
      20,  // 下
      0, // 左
    ]
  },
  xAxis: {
    type: 'category',
    data: [],// ['饲料类型1', '饲料类型2', '饲料类型3', '饲料类型4'],
    axisLabel: {
      color: '#FFFFFF'
    }
  },
  tooltip: {
    trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
    axisPointer: {// 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    },
    formatter: '{b} : {c}吨',
  },
  yAxis: [{
    type: 'value',
    axisLabel: {
      color: '#FFFFFF'
    },
    splitLine: {
      lineStyle: {
        color: '#4B4743',
        type: 'dashed'
      }
    },
  }, {
    type: 'value',
    splitLine: false,
    show: false,
  },
  {
    type: 'value',
    splitLine: false,
    show: false,
  }
  ],
  grid: {
    top: 60,
    left: 50,
    botttom: 0,
  },
  series: [
    {
      name: '',//'本地',
      data: [],// [12, 35, 41, 33],
      type: 'bar',
      barWidth: '15',
      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    },
    {
      name: '',//'外地进口',
      data: [],//[22, 45, 51, 13],
      type: 'bar',
      barWidth: '15',
      yAxisIndex: 1,
      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    }
  ]
}
