/*
 * @Author: hlc 1664800317@qq.com
 * @Date: 2022-09-06 18:15:21
 * @LastEditors: Pancras
 * @LastEditTime: 2022-09-30 17:59:31
 * @FilePath: \korlascreen\src\components\main-view2\top-btn1\bottom-btn3\asideLeft\leftBottomItem\option.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  color: [{
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#5883FF' // 0% 处的颜色
    }, {
      offset: 1, color: '#51BAFF' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }, {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#00E5B4' // 0% 处的颜色
    }, {
      offset: 1, color: '#AEFFE2' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }],
  legend: {
    data: [],// ['收益/万元'],
    textStyle: {
      color: '#FFFFFF'
    },
    itemWidth: 26,
    itemHeight: 6,
    itemStyle: {
      color: '#02C92F'
    },
    padding: [
      20,  // 上
      0, // 右
      20,  // 下
      0, // 左
    ],
  },
  xAxis: {
    type: 'category',
    data: [],// ['2019年', '2020年', '2021年', '2022年'],
    axisLabel: {
      color: '#FFFFFF'
    },
    axisTick: {
      show: false
    },
  },
  tooltip: {
    trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
    axisPointer: {// 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    },
    formatter: function (params) {
      var relVal = params[0].name
      for (var i = 0, l = params.length; i < l; i++) {
        relVal += '<br/>' + params[i].marker + params[i].value + '亩'
      }
      return relVal
    }
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      color: '#FFFFFF'
    },
    splitLine: {
      lineStyle: {
        color: '#495A4D',
        type: 'dashed'
      }
    },
    data: [],// ['收益/万元']
  },
  grid: {
    top: 60,
    botttom: 0,
  },
  series: [
    {
      name: '',//'收益/万元',
      data: [],//[12, 35, 41, 33],
      type: 'line',
      smooth: true,
      lineStyle: {
        color: '#02C92F',
        shadowOffsetX: 0,
        shadowOffsetY: 3,
        shadowBlur: 6,
        shadowColor: "#02C92F"
      }
    }
  ]
}
